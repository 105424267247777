<!--
 * @Description: 房间结算
 * @Author: 琢磨先生
 * @Date: 2022-10-13 10:11:34
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-10-14 15:40:33
-->

<template>
  <el-card class="box">
    <el-form :model="query" ref="query" :inline="true">
      <el-form-item label="商户">
        <el-input v-model="query.q" clearable placeholder="商户名称"></el-input>
      </el-form-item>
      <el-form-item label="日期">
        <el-date-picker
          v-model="query.date"
          value-format="YYYY-MM-DD"
          format="YYYY-MM-DD"
          clearable
          placeholder="结算账期"
          :disabled-date="(t) => t > new Date()"
        ></el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" round @click="onSearch" icon="search"
          >查询</el-button
        >
      </el-form-item>
    </el-form>
    <div>
      <el-button type="primary" icon="plus" round size="small"
        >创建结算</el-button
      >
    </div>
  </el-card>

  <el-card class="box data" v-loading="loading">
    <el-table :data="tableData.data" border stripe>
      <el-table-column label="结算账期" width="220">
        <template #default="scope">
          {{ scope.row.start_date }}至{{ scope.row.end_date }}
        </template>
      </el-table-column>
      <el-table-column
        label="状态"
        width="120"
        prop="status_text"
      ></el-table-column>
      <el-table-column
        label="应收金额"
        width="140"
        prop="decimal_amount"
      ></el-table-column>
      <el-table-column label="收款记录" width="140"></el-table-column>
      <el-table-column label="应开票金额" width="140"></el-table-column>
      <el-table-column
        label="创建时间"
        prop="create_at"
        width="180"
      ></el-table-column>
      <el-table-column label="操作" width="200">
        <el-button type="primary" link size="small">订单明细</el-button>
        <el-button type="primary" link>重新生成</el-button>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="pageSizeChange"
      @current-change="pageCurrentChange"
      :current-page="query.pageIndex"
      :page-sizes="[20, 40, 60, 80, 100]"
      :page-size="query.pageSize"
      layout="total, sizes, prev, pager, next"
      :total="tableData.counts"
    >
    </el-pagination>
  </el-card>

  <el-dialog
    title="创建结算数据"
    v-model="dialogVisible"
    width="900px"
    top="10vh"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <!-- <el-table :data="formTable" border>
      <el-table-column label="商户" width="200">
      </el-table-column>
      <el-table-column label="账期">
        <template #default="scope">
            <el-date-picker v-model="scope.row.dates" value-format="YYYY-MM-DD" format="YYYY-MM-DD" placeholder=""></el-date-picker>
        </template>
      </el-table-column>
      <el-table-column label="备注" width="200"></el-table-column>
    </el-table> -->
    <el-alert type="info" title="" v-for="item in formTable" :key="item" :closable ="false">
      <el-form :model="item" :rules="rules" :inline="true">
        <el-form-item label="账期">
          <el-date-picker style="width:240px;"
            v-model="item.dates"
            type="daterange"
            start-placeholder="Start date"
            end-placeholder="End date"
          />
        </el-form-item>
        <el-form-item>
          <el-select v-model="item.business_id" placeholder="">
            <el-option
              :label="x.name"
              :value="x.id"
              v-for="x in business_list"
              :key="x"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注">
          <el-input 
            v-model="item.remark"
            type="textarea"
            placeholder=""
          ></el-input>
        </el-form-item>
      </el-form>
    </el-alert>
    <el-button type="primary" link size="small" icon="plus">添加</el-button>
    <template #footer>
      <el-button type="primary" :loading="saving" @click="onSubmit"
        >确 定</el-button
      >
    </template>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      loading: true,
      dialogVisible: true,
      saving: false,
      query: {
        pageIndex: 1,
        pageSize: 20,
        order: {
          sort: "",
          fieldName: "",
        },
        q: "",
      },
      tableData: {
        counts: 0,
      },
      //表格表单列表
      formTable: [{}],
      form: {},
      rules: {},
      refList: [],
      business_list: [],
    };
  },
  created() {
    this.$http.get("admin/v1/business/all").then((res) => {
      if (res.code == 0) {
        this.business_list = res.data;
      }
    });
    this.loadData();
  },
  methods: {
    onSearch() {
      this.query.pageIndex = 1;
      this.loadData();
    },

    /**
     *
     */
    loadData() {
      this.$http.post("admin/v1/houseSettle", this.query).then((res) => {
        if (res.code == 0) {
          this.tableData = res.data;
        }
        this.loading = false;
      });
    },

    /**
     *
     */
    onSubmit() {},

    /**
     *
     */
    setRefItem(el) {
      this.refList.push(el);
    },

    /**
     * 排序
     */
    onSort(e) {
      if (e.prop) {
        this.query.order.sort = e.order;
        this.query.order.fieldName = e.prop;
        this.loadData();
      }
    },
    /**
     * 分页页数更改
     */
    pageSizeChange(val) {
      this.query.pageSize = val;
      this.loadData();
    },
    /**
     * 分页页码更改
     */
    pageCurrentChange(val) {
      this.query.pageIndex = val;
      this.loadData();
    },
  },
};
</script>

<style scoped>
</style>